<template>
  <a
    class="nav-link casino-btn casino-btn-pink csn-nav-btn-register"
    data-title="Register Account"
    @click="clickOnRegisterButton"
  >
    <span class="csn-nav-register-btn-desktop-label">
      {{ t('createAccount') }}
    </span>
    <span class="csn-nav-register-btn-mobile-label">
      {{ t('register') }}
    </span>
  </a>
</template>
<script>
import { mapGetters } from 'vuex'

import {
  NAVBAR__REGISTER_BUTTON,
  Module,
  IS_MOBILE_SCREEN,
  RouteName,
} from '@/constants'
import { openRegisterModal, dest } from '@/helpers'

export default {
  name: NAVBAR__REGISTER_BUTTON,
  computed: {
    t() {
      return this.$createComponentTranslator(NAVBAR__REGISTER_BUTTON)
    },
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
  },

  methods: {
    clickOnRegisterButton() {
      this.isMobileScreen
        ? this.$router.push({ name: RouteName.REGISTRATION })
        : openRegisterModal()
    },
  },
}
</script>
